<template>
  <div class="landing">
    <div class="introduction-image">
      <img alt="Muziek Dichtbij Willemien" src="/images/homebanner.webp">
    </div>
    <section class="logo-section">
      <div class="container">
        <div class="centered-logo">
          <img alt="Willemien van Gelder" src="/images/logo.png">
        </div>
      </div>
    </section>
    <section class="video-section">
      <div class="video-section-backdrop"></div>
      <div class="video-container container">
        <iframe allowfullscreen class="video" height="345"
                src="https://www.youtube-nocookie.com/embed/bm_UPtxtCuo?si=cwq5JFAZEjEyUKq-&rel=0"
                title="Vacature video"
                width="420"></iframe>
      </div>
    </section>
    <section id="over-mij" class="text-image"
             style="flex-direction: row-reverse; padding-top: 100px; margin-top: -50px">
      <div class="container">
        <div class="text-side" data-aos="fade-right" data-aos-duration="1200">
          <h1 class="page-title" style="font-weight: 600">Muziek zorgt voor een lach en een traan.</h1>
          <span class="stripe"></span>
          <p>
            Muziek Dichtbij geeft huiskamerconcerten bij voornamelijk zorginstellingen. Dit kan
            onder andere een verpleeghuis, ziekenhuis, (kinder)hospice zijn.
            De huiskamerconcerten zijn op groeps- en individueel niveau, waarbij persoonlijk
            contact centraal staat. De combinatie van live muziek en persoonlijk contact zorgt
            vaak voor een lach en een traan. Het geeft mensen de ruimte om weer terug te gaan
            naar een herinnering van eerder.
            <br>
            <br>
            Naast dat ik bij zorginstellingen speel kan ik samen met andere muzikanten tijdens
            uw receptie, ceremonie, personeelsfeestje, bruiloft of uitvaart akoestische
            achtergrond muziek verzorgen.
          </p>
        </div>
        <div class="img-side">
          <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/7.webp"
               style="border-radius: 40px; max-height: 500px">
        </div>
      </div>
    </section>
    <section id="aanbod" class="aanbod" style="padding-top: 120px; margin-top: -120px; margin-bottom: 60px;">
      <h3>Aanbod</h3>
      <div class="container grid-container">
        <div class="img-side hero-image">
          <img alt="Willemien van Gelder Muziek Dichtbijs" src="/images/concert.webp">
          <div class="img-overlay">
            <div class="overlay-text">
              <p class="overlay-title">
                Groot huiskamerconcert
              </p>
              <p class="overlay-desc">Als de groep groter is dan 25 mensen kan ik een huiskamerconcert verzorgen...</p>
              <router-link to="/groot-huiskamerconcert">Meer info</router-link>
            </div>
          </div>
        </div>
        <div class="img-side hero-image">
          <img alt="Willemien van Gelder Muziek Dichtbijs" src="/images/duoo.webp">
          <div class="img-overlay">
            <div class="overlay-text">
              <p class="overlay-title">
                Duo
              </p>
              <p class="overlay-desc">Wij zijn Willemien en Waisca. Samen maken we reguliere en/of christelijke
                muziek...</p>
              <router-link to="/duo">Meer info</router-link>
            </div>
          </div>
        </div>
        <div class="img-side hero-image">
          <img alt="Willemien van Gelder Muziek Dichtbijs" src="/images/solo.webp">
          <div class="img-overlay">
            <div class="overlay-text">
              <p class="overlay-title" style="font-size: 20px">
                Klein huiskamerconcert/ <br>1 op 1
              </p>
              <p class="overlay-desc">In veel zorginstellingen zijn er kleine huiskamers aanwezig. Als...</p>
              <router-link to="/klein-huiskamerconcert">Meer info</router-link>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section class="text-image">
      <div class="container" style="flex-direction: row-reverse;">
        <div class="text-side" data-aos="fade-right" data-aos-duration="1200">
          <p style="font-size: 18px"><em>Recensie</em></p>
          <h1 class="page-title" style="font-weight: 600">Muziek Dichtbij in de Hullen</h1>
          <span class="stripe"></span>
          <p>
            Wat een toevoeging voor de bewoners van de Hullen dat Willemien één keer per
            maand bij ons muziek komt brengen!
            De ene keer in ons restaurant waar alle bewoners bij aan kunnen sluiten.
            De andere keer in een kleinere setting in de huiskamer of zelfs aan het bed van onze
            bewoners.
          </p>
          <br>
          <p>We zien bij de bewoners altijd een positief effect ontstaan, er komen vaak mooie
            herinneringen of emoties boven. Willemien voelt de situatie en de mensen heel goed
            aan om daarop in te spelen. Even oogcontact of een hand op de schouder zijn voor
            Willemien vanzelfsprekende en natuurlijke handelingen. De bewoners krijgen nieuwe
            energie en vergeten even hun zorgen en kwaaltjes. Bewoners kunnen fijn
            meezingen met de bladmuziek die Willemien ons van tevoren heeft toegestuurd of
            gewoon lekker luisteren. Alles mag en kan.</p>
          <br>
          <p>Er heerst altijd een rustige en gemoedelijke sfeer als Muziek Dichtbij op de Hullen is.
            Altijd fijn als Willemien de Hullen binnen komt wandelen met een bolderkar vol
            muziekinstrumenten.
            <br>
            En.......de bewoners kijken er altijd naar uit!</p>
        </div>
        <div class="img-side">
          <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/baken.webp"
               style="border-radius: 40px; max-height: 600px">
        </div>
      </div>
    </section>
    <section class="social-media">
      <div class="container">
        <div class="social-heading">
          <div class="left-side">
            <a href="https://www.instagram.com/muziekdichtbij/" target="_blank">@Muziekdichtbij</a>
            <h4>Volg ons op instagram </h4>
          </div>
          <div class="right-side" data-aos="fade-left" data-aos-duration="1200">
            <a href="https://www.instagram.com/muziekdichtbij/" target="_blank">Volgen</a>
          </div>
        </div>
        <div class="social-content" data-aos="fade-up" data-aos-duration="1200">
          <div class="div1">
            <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/1.webp">
          </div>
          <div class="div2">
            <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/gray.webp">
          </div>
          <div class="div3">
            <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/7.webp">
          </div>
          <div class="div4">
            <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/4.webp">
          </div>
          <div class="div5">
            <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/5.webp">
          </div>
          <div class="div6">
            <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/6.webp">
          </div>
          <div class="div7">
            <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/2.webp">
          </div>
        </div>
      </div>
    </section>
    <br>
  </div>
</template>

<style lang="scss" scoped>

$blue: #475023;
$blue-d2: #C99D66;
$coal: #C99D66;

.video-section {
  position: relative;

  .video-section-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 130px;
    //background-color: #C99D66;
  }

  .video-headline {
    display: flex;
    align-items: center;
    padding-top: 60px;

    h4 {
      color: #C99D66;
    }

    .title-line {
      height: 3px;
      width: 100%;
      background-color: #C99D66;
    }

    .video-title {
      font-size: 44px;
      width: fit-content;
      min-width: 400px;
      @media (max-width: 500px) {
        font-size: 30px;
      }
    }
  }

  .video-container {
    position: relative;
    width: 500px;
    min-width: 60%;
    height: 600px;
    max-height: 40vw;
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @media (max-width: 800px) {
      max-width: 90vw;
      height: 300px;
      max-height: unset;
    }

    .video {
      position: absolute;
      border: none;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
        border-radius: 16px;
    }
  }
}

.aanbod {
  margin-top: 100px !important;

  h3 {
    text-align: center;
    color: #C99D66;
    padding-bottom: 24px;
  }

  .img-side {
    position: relative;

    .img-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: 600px;
      border-radius: 24px;
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      transition: .3s;
      max-width: 600px;
      min-height: 500px;

      .overlay-text {
        position: absolute;
        bottom: 24px;
        left: 24px;

        p, a {
          color: white;
        }

        .overlay-title {
          font-size: 36px;
          font-weight: 600;
        }

        .overlay-desc {
          font-size: 18px;
          font-weight: 300;
          padding-right: 16px;
        }

        a {
          display: inline-block;
          margin-top: 6px;
        }
      }
    }
  }
}

.introduction-image {
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    @media (max-width: 800px) {
      height: 300px;
      margin-top: 80px;
    }
  }
}

.logo-section {
  .container {
    padding-bottom: 0;
  }

  .centered-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 400px;
      max-width: 90vw;
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 30px;
  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
  }

  .img-side {
    &:hover {
      img, .img-overlay {
        transform: scale(1.05);
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 600px;
    min-height: 500px;
    transition: .3s;
  }
}

.landing {
  .hero-banner-div {
    position: relative;
    @media (max-width: 600px) {
      height: 300px;
    }

    .hero-banner {
      width: 100%;
      margin-top: -100px;
      min-height: 100%;
      object-fit: cover;
      @media (max-width: 750px) {
        margin-top: 20px;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(34, 34, 34, 0.4);
    }
  }

  .hero-image {
    img {
      max-width: 600px;
      border-radius: 24px;
    }
  }

  .hero-navigator {
    background-color: white;
    width: 100%;
    margin-top: -200px;
    position: relative;
    padding: 45px 60px 60px;
    box-shadow: 0 4px 4px rgba(197, 197, 197, 0.25);

    @media (max-width: 1450px) {
      max-width: 90vw;
      margin-top: -170px;
    }

    @media (max-width: 1200px) {
      margin-top: -100px;
    }

    @media (max-width: 1000px) {
      max-width: 95vw;
      margin-top: -70px;
    }

    @media (max-width: 900px) {
      margin-top: 0;
    }

    @media (max-width: 750px) {
      padding: 25px;
      max-width: 90vw;
      margin-top: -60px;
    }


    .headline {
      h1 {
      }

      p {
        margin-bottom: 15px;
        color: #656565;

        @media (max-width: 750px) {
          margin-top: 20px;
          font-size: 20px;
        }

      }

      hr {
        border: none;
        background-color: $blue;
        width: 40px;
        height: 3px;
        margin: 0;
      }
    }

    .navigators {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      height: 230px;
      position: relative;
      @media (max-width: 1450px) {
        height: 180px;
      }

      @media (max-width: 1200px) {
        height: 150px;
      }

      @media (max-width: 750px) {
        display: grid;
        height: unset;
        .different-navigator {
          height: 200px !important;
        }
      }


      .different-navigator {
        &::before {
          clip-path: polygon(88% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
          background: #222222 !important;
        }

        &::after {
          clip-path: polygon(88% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
        }
      }

      a {
        flex: 0 1 100%;
        cursor: default;
      }

      .single-navigator {
        width: 100%;
        height: 100%;
        max-width: 100%;
        position: relative;
        z-index: 4;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(47.13deg, rgba(30, 30, 30, 0.8) 0%, rgba(207, 215, 255, 0) 97.4%);
          z-index: 1;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .zekerheden {
    background-color: $blue-d2;

    .container {
      padding-top: 70px;
      padding-bottom: 70px;

      b {
        color: $coal;
        font-size: 16px;
      }

      h3 {
        margin-top: 10px;
        font-weight: 800;
        font-size: 42px;
        color: white;
      }

      .items-grid {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px 80px;
        @media (max-width: 950px) {
          grid-template-columns: 1fr;
        }

        .single-item {
          border-left: solid 2px $coal;
          min-height: 140px;
          height: auto;
          display: grid;
          align-items: stretch;
          padding-left: 15px;

          h5 {
            font-weight: 800;
            font-size: 22px;
            margin-top: 10px;
            color: white;
          }

          p {
            margin-top: -5px;
            color: white;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .social-media {
    margin: 80px 0 100px;

    .container {
      .social-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 950px) {
          align-items: flex-start;
          flex-direction: column;
          gap: 20px;
        }

        a {
          text-decoration: none;
          font-family: 'Avenir LT Std', sans-serif;
        }

        .right-side {
          height: auto;

          a {
            align-items: center;
            padding: 12px 50px;
            font-weight: 700;
            font-size: 20px;
            color: white;
            background-color: $coal;
            transition: .25s ease;

            &:hover {
              background-color: #8a6c49;
            }
          }
        }

        .left-side {
          a {
            color: #474747;
            font-size: 22px;
            font-weight: 500;
          }

          h4 {
            font-weight: 900;
            font-size: 42px;
            color: #222222;
          }
        }
      }

      .social-content {
        display: grid;
        margin-top: 70px;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        @media (max-width: 950px) {
          display: block;
          height: unset;
          .div4, .div5, .div5, .div6, .div7, .div8 {
            display: none;
          }
        }
        height: 400px;
        gap: 25px;

        div {
          background: rgba(217, 217, 217, 0.6);
          overflow: hidden;
          border-radius: 8px;
        }

        img {
          width: 100%;
          margin: auto;
          object-fit: cover;
          height: 100%;
        }
      }

      .div1 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .div2 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .div3 {
        grid-area: 1 / 2 / 3 / 4;
      }

      .div4 {
        grid-area: 1 / 4 / 2 / 5;
      }

      .div5 {
        grid-area: 1 / 5 / 2 / 6;
      }

      .div6 {
        grid-area: 2 / 5 / 3 / 6;
      }

      .div7 {
        grid-area: 2 / 4 / 3 / 5;
      }
    }
  }

  .faq-section {
    .total-faq-headline {
      padding: 40px 0;
      background-color: $blue;
      margin-bottom: 50px;

      .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .pill {
          width: fit-content;
          background-color: #DEEBFF;
          color: $blue;
          font-weight: 800;
          font-size: 16px;
          margin-bottom: 20px;
          cursor: default;
          border: none;
          padding: 10px 16px 8px;
          border-radius: 28px;
          font-family: 'Avenir LT Std', sans-serif;
        }

        h3, p {
          color: white;
        }

        h3 {
          font-size: 26px;
        }

        p {
          margin-top: 15px;
          font-family: 'Urbane', sans-serif;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .faq-content {
      .container {
        display: flex;
        flex-direction: column;
        max-width: 900px;

        .single-faq-item {
          cursor: pointer;
          overflow: hidden;
          border-top: solid 1px #ECEFF1;
          padding: 40px 0;

          .faq-headline {
            display: flex;
            justify-content: space-between;
            padding: 16px;

            h4 {
              font-size: 28px;
              font-weight: 600;
              @media (max-width: 700px) {
                font-size: 24px;
              }
            }

            svg {
              transition: .3s;
              transform: rotate(90deg);
              margin-right: 15px;
            }
          }

          .faq-content {
            font-size: 18px;
            font-weight: 300;
            padding: 0;
            height: 0;
            opacity: 0;
            transition: all .2s ease;
            overflow: hidden;
          }
        }

        .active-faq {
          .faq-headline {
            svg {
              transform: rotate(-90deg);
            }
          }

          .faq-content {
            height: auto;
            padding: 0 16px;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>

<script>
import AOS from "aos";

import TextImage from '@/components/TextImage.vue'
import Voordelen from "@/components/Voordelen";

export default {
  name: 'HomeView',
  components: {
    Voordelen,
    TextImage
  },
  data() {
    return {
      showedFaq: false,
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    AOS.init();

    // if url got #over-mij, scroll to that section
    if (window.location.hash === '#over-mij') {
      const element = document.getElementById('over-mij');
      element.scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>
