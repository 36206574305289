<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1>Ervaringen</h1>
      </template>
    </sub-banner>
    <section style="margin-top: -50px">
      <div class="container verhalen-container">
        <div>
          <div>

          <p><strong>Gebeurtenissen Muziek Dichtbij<br/></strong></p>
          <p>
            Een mevrouw was terminaal, ze kon alleen nog maar op bed liggen. Ik kwam bij haar
            aan bed, ik vertelde hoe ik heette en dat ik haar lievelingslied kwam zingen. Haar blik
            werd open en ze keek mij indringend aan. Vervolgens begon ik het lied: Veilig in
            Jezus armen, voor haar te zingen. Het was bijzonder en intiem. Ze zong zachtjes
            mee en aan het einde van het lied, gaf ze aan hoe mooi het was. De tranen stonden
            in haar ogen.</p>
          </div>
        </div>
        <br>
        <hr>
        <br>
        <div>
         <p>De glimlach van een kind staat centraal bij het zingen voor kinderen. De kinderen bij
           wie ik muziek maak zijn of ernstig ziek en/of lichamelijk/ verstandelijk beperkt. Muziek
           zorgt ervoor dat we even contact kunnen maken. En soms ontstaat er een mooie
           glimlach op het gezicht.</p>
          <img alt="" src="/images/duo.webp">
        </div>
        <br>
        <hr>
        <br>
        <div>
          <p>Een mevrouw van bijna 100 jaar was aanwezig bij een huiskamerconcert. Op het
            moment dat ik daar ruist langs de wolken wilde gaan zingen, gaf de begeleidster aan
            dat dit het lievelingslied was van die mevrouw, maar ze kon weinig meer horen. Ik
            ben dichtbij haar gaan zitten en samen hebben we het lied gezongen. Ze straalde en
            genoot van het moment.</p>
        </div>
        <br>
        <hr>
        <br>
        <div>
          <img alt="" src="/images/6.webp">
         <p>Ik was bij een mevrouw van 93 jaar, haar dochter was ook aanwezig. Mevrouw had
           veel pijn, maar haalde veel kracht uit oude liederen. Ik ben naast haar gaan zitten
           met mijn gitaar en samen zijn we de oude liederen gaan zingen. Op een gegeven
           moment ben ik gestopt met zingen en zong ze alleen. De dochter van mevrouw was
           zeer ontroerd door dit moment.</p>
        </div>
        <br>
        <hr>
        <br>
        <div>
         <p>Een mevrouw was terminaal. Ze kon alleen nog maar in bed liggen en had veel pijn.
           Ze was zichtbaar verzwakt. Ik heb een aantal liederen voor haar gezongen. Na 2
           liederen gaf ik aan dat het misschien beter was dat ik ging stoppen, ze wilde dat ik
           meer liederen voor haar speelde. Dit heb ik ook gedaan. Ik heb haar hand gepakt en
           samen contact gemaakt. Prachtig hoe zo’n klein moment zo groot kan worden.</p>
          <img alt="" src="/images/2.webp">
        </div>
        <br>
        <hr>
        <br>
        <p>Ik was bij een mevrouw, ze kwam uit Amsterdam en had veel gereisd in haar leven.
          Ze was zwaar dementerend. Het was eerst even aftasten, maar op een gegeven
          moment begon ze mee te zingen. Prachtig hoe het weer boven kwam. Ze moest
          lachen en was zichtbaar aan het genieten. Ze heeft meerdere malen gezegd: Dit vind
          ik leuk :)</p>
        <br>
      </div>
    </section>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";

export default {
  name: "Verhalen",
  components: {
    SubBanner,
    TextImage
  },
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;

.contact-title {
  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 30px;
  }
}

.verhalen-container {
  > div {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-items: center;

    > p {
      flex: 1;
      min-width: 400px;
      @media (max-width: 500px) {
        min-width: 100%;
      }
    }

    img {
      width: 100%;
      max-width: 400px;
      border-radius: 16px;
    }
  }
}

</style>
