<template>
  <div class="footer">
    <div class="footer-col left-side">
      <img alt="Muziek Dichtbij Willemien van Gelder" src="/images/logo.png">
      <div class="base-info">
        <b>Muziek Dichtbij</b>
        <p>Molenpad 6</p>
        <p>8097 PH Oosterwolde</p>
      </div>
    </div>

    <div class="footer-col">
      <h4>Socials</h4>
      <div class=social-links>
        <a href=https://wa.me/+31625587047 target=_blank>
          <svg fill="none" height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg">
            <rect fill="white" height="36" rx="3" width="36"></rect>
            <path clip-rule="evenodd"
                  d="M9 17.9999C9 13.037 13.037 9 17.9999 9V9.00015H18.0052C22.9681 9.00015 27.0051 13.0371 27.0051 18.0001C27.0051 22.9629 22.9681 27 18.0052 27C16.1744 27 14.476 26.4551 13.0528 25.5133L9.59252 26.6189L10.7142 23.2751C9.63493 21.7935 9 19.9682 9 17.9999ZM14.9154 13.127C15.1798 13.1377 15.3121 13.1536 15.4867 13.5715C15.5431 13.7076 15.6205 13.8955 15.7054 14.1015C15.9472 14.688 16.2492 15.4206 16.2962 15.5185C16.3596 15.6508 16.4232 15.8308 16.3332 16.0053C16.2544 16.1727 16.1849 16.2532 16.069 16.387C16.0603 16.3971 16.0514 16.4074 16.0422 16.4181C15.9856 16.4838 15.9299 16.5427 15.8744 16.6014C15.8002 16.6799 15.7263 16.7581 15.6506 16.852C15.6485 16.8545 15.6464 16.857 15.6443 16.8595C15.5244 17.0002 15.3941 17.153 15.5449 17.4129C15.6983 17.6721 16.2273 18.5345 17.0052 19.2275C17.9283 20.0484 18.6907 20.3567 19.0345 20.4957C19.0652 20.5081 19.0925 20.5192 19.1163 20.5291C19.3331 20.6192 19.5925 20.598 19.7512 20.4286C19.9233 20.2432 20.1301 19.953 20.3453 19.6511C20.3817 19.6001 20.4183 19.5487 20.455 19.4974C20.6348 19.2434 20.8622 19.2116 21.1004 19.3015C21.3438 19.3862 22.6296 20.0211 22.8941 20.1534C22.9481 20.1805 22.9983 20.2046 23.0447 20.2269C23.2252 20.3137 23.3462 20.3719 23.3967 20.4603C23.4603 20.5714 23.4603 21.0952 23.2433 21.7091C23.0264 22.3227 22.1639 22.8308 21.4761 22.9789C21.0053 23.0794 20.3914 23.1589 18.3227 22.3018C15.8723 21.2871 14.2299 18.9063 13.8958 18.422C13.8692 18.3835 13.8509 18.3569 13.8411 18.3441C13.8374 18.3389 13.833 18.3329 13.8279 18.326C13.6598 18.0962 12.7724 16.8829 12.7724 15.6296C12.7724 14.3748 13.3923 13.7449 13.6699 13.4628C13.6779 13.4546 13.6856 13.4468 13.693 13.4392C13.91 13.217 14.2697 13.1165 14.6137 13.1165C14.7144 13.117 14.815 13.1205 14.9154 13.127Z"
                  fill="#0A0F16"
                  fill-rule="evenodd"></path>
          </svg>
        </a>
        <a href="https://www.instagram.com/muziekdichtbij/" target="_blank">
          <svg fill="none" height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg">
            <rect fill="white" height="36" rx="3" width="36"></rect>
            <path clip-rule="evenodd"
                  d="M9 18C9 14.4049 9 12.6073 9.85669 11.3126C10.2392 10.7344 10.7344 10.2392 11.3126 9.85669C12.6073 9 14.4049 9 18 9C21.5951 9 23.3927 9 24.6874 9.85669C25.2656 10.2392 25.7608 10.7344 26.1433 11.3126C27 12.6073 27 14.4049 27 18C27 21.5951 27 23.3927 26.1433 24.6874C25.7608 25.2656 25.2656 25.7608 24.6874 26.1433C23.3927 27 21.5951 27 18 27C14.4049 27 12.6073 27 11.3126 26.1433C10.7344 25.7608 10.2392 25.2656 9.85669 24.6874C9 23.3927 9 21.5951 9 18ZM22.6593 18.0002C22.6593 20.5735 20.5732 22.6596 17.9998 22.6596C15.4265 22.6596 13.3404 20.5735 13.3404 18.0002C13.3404 15.4269 15.4265 13.3408 17.9998 13.3408C20.5732 13.3408 22.6593 15.4269 22.6593 18.0002ZM17.9998 21.0832C19.7025 21.0832 21.0828 19.7029 21.0828 18.0002C21.0828 16.2975 19.7025 14.9172 17.9998 14.9172C16.2971 14.9172 14.9168 16.2975 14.9168 18.0002C14.9168 19.7029 16.2971 21.0832 17.9998 21.0832ZM22.8433 14.2015C23.4479 14.2015 23.9381 13.7114 23.9381 13.1067C23.9381 12.5021 23.4479 12.0119 22.8433 12.0119C22.2386 12.0119 21.7485 12.5021 21.7485 13.1067C21.7485 13.7114 22.2386 14.2015 22.8433 14.2015Z"
                  fill="#0A0F16"
                  fill-rule="evenodd"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

$gold: #C2A484;
$gold-d2: #E3D4C2;
$coal: #475023;


.footer {
  display: flex;
  justify-content: space-between;
  background-color: #C99D66;
  padding: 36px 24px;
  margin: 12px;
  border-radius: 24px;

  li::marker {
    color: white !important;
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: center;
    .footer-col {
      width: 100% !important;
    }
  }

  .footer-col {
    width: 25%;
    padding: 0 15px;

    img {
      width: 100%;
      max-width: 200px;
      background-color: white;
      padding: 24px;
      border-radius: 24px;
    }

    .social-links {
      display: flex;
      gap: 8px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
      }
    }

    ul {
      padding: 0;
      li {
        color: white;
      }
    }

    a {
      color: white;
      text-decoration: none;
      font-weight: 600;
    }

    h4 {
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 35px;
      font-weight: 700;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        background-color: white;
        height: 2px;
        box-sizing: border-box;
        width: 50px;
      }
    }
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 35%;
    @media (max-width: 1050px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }
    b, p {
      color: white;
    }
    b {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
}
</style>

<script>
export default {
  name: 'FooterBar',
}
</script>
